import { Box, Button, Text } from "@chakra-ui/react";
import React from "react";

const UserChoiceGrid = ({
  usersChoices,
  handleUserChoice,
  currentUserChoice,
  isPlayerActive,
}) => {
  return (
    <Box marginTop={["24px", "56px"]}>
      {isPlayerActive ? (
        <>
          {usersChoices.length ? (
            <Text textAlign={"center"} fontSize="24px">
              Choose
            </Text>
          ) : null}
          <Box display="flex" gap="6px">
            {usersChoices.map((userChoice, index) => (
              <Button
                height={["50px"]}
                width={["50px"]}
                colorScheme={
                  currentUserChoice
                    ? currentUserChoice === userChoice
                      ? "teal"
                      : null
                    : null
                }
                key={index}
                onClick={() => {
                  handleUserChoice(userChoice);
                }}
              >
                {userChoice}
              </Button>
            ))}
          </Box>
        </>
      ) : null}
    </Box>
  );
};

export default UserChoiceGrid;
