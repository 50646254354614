import { Box, Grid, GridItem } from "@chakra-ui/react";
import React from "react";
import TicTacToeCell from "./TicTacToeCell";

const TicTacToeGrid = ({
  state,
  gameActive,
  handleGameCellClick,
  isPlayerActive,
}) => {
  return (
    <Box
      // height="70%"
      display={"flex"}
      width="100%"
      justifyContent={"center"}
      alignItems="center"
    >
      <Box>
        <Grid
          templateColumns={["repeat(3, 50px)", "repeat(3, 100px)"]}
          gap={["3", "6"]}
        >
          {state.map((rowValue, rowIndex) => (
            <GridItem
              key={rowIndex}
              w={["50px", "100px"]}
              h={["50px", "100px"]}
            >
              <TicTacToeCell
                onCellClick={() => handleGameCellClick(rowIndex)}
                disabled={rowValue || !isPlayerActive || !gameActive}
                value={rowValue || "-"}
              />
            </GridItem>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default TicTacToeGrid;
