import { Box, Button, ButtonGroup } from "@chakra-ui/react";
import React, { useEffect, useCallback, useReducer, useState } from "react";
import db, { downloadModel } from "./db";
import GameStats from "./components/GameStats";
import TicTacToeGrid from "./components/TicTacToeGrid";
import UserChoiceGrid from "./components/UserChoiceGrid";
import gameReducer, { allowedPositions, allowedValues } from "./gameReducer";

const Game = () => {
  const [ready, setReady] = useState(false);
  const [userChoice, setUserChoice] = useState(null);
  const [game, dispatch] = useReducer(gameReducer, {
    isPlayerActive: false,
    currentUserChoice: null,
    state: Array(9).fill(null),
    gameActive: true,
    playerWon: 0,
    agentWon: 0,
    totalGames: 0,
  });

  async function initModel() {
    // load model is actions table is empty
    const count = await db.actions.count();
    if (count === 0) await downloadModel();
    setReady(true);
  }

  const agentAction = useCallback(async () => {
    const currentState = game.state.map((val) => val || "x").join("-");
    const bestAction = await db.actions.get(currentState);
    dispatch({ type: "AGENT", payload: bestAction });
  }, [game.state]);

  const reset = () => dispatch({ type: "RESET" });

  const userAction = (position) => {
    if (
      position.toString() &&
      userChoice &&
      allowedPositions(game.state).includes(position)
    ) {
      dispatch({ type: "USER", payload: [position, userChoice] });
      setUserChoice(null);
    } else {
      alert("Pick a value first");
    }
  };

  const handleUserChoice = (userChoice) => {
    if (allowedValues(game.state)[1].includes(userChoice))
      setUserChoice(userChoice);
    else alert("Cannot choose this value");
  };

  useEffect(() => {
    if (game.state.every((val) => !val)) return;
    if (!game.isPlayerActive) agentAction();
  }, [game.isPlayerActive, agentAction, game.state]);

  return (
    <Box
      minH="100vh"
      display="flex"
      alignItems="center"
      flexDirection={"column"}
    >
      <GameStats
        gameActive={game.gameActive}
        wins={game.playerWon}
        losses={game.agentWon}
        total={game.totalGames}
        turn={game.isPlayerActive ? "Player" : "Agent"}
      />

      <TicTacToeGrid
        gameActive={game.gameActive}
        isPlayerActive={game.isPlayerActive}
        state={game.state}
        handleGameCellClick={userAction}
      />

      <UserChoiceGrid
        usersChoices={allowedValues(game.state)[1]}
        handleUserChoice={handleUserChoice}
        currentUserChoice={userChoice}
        isPlayerActive={game.isPlayerActive}
      />

      <ButtonGroup marginTop={"2"}>
        {ready ? (
          game.state.every((val) => !Boolean(val)) ? (
            <Button onClick={agentAction}>Start</Button>
          ) : (
            <Button onClick={reset}>Reset</Button>
          )
        ) : (
          <Button onClick={initModel}>Load Model</Button>
        )}
      </ButtonGroup>
    </Box>
  );
};

export default Game;
