import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

const GameStats = ({ gameActive, wins, losses, total, turn }) => {
  return (
    <Flex
      direction="column"
      width={"100%"}
      padding={["16px", "24px"]}
      justifyContent={"center"}
      marginBottom={["56px", "72px"]}
    >
      <Flex justifyContent="space-evenly" marginBottom={["24px", "56px"]}>
        <Box textAlign={"center"}>
          <Text fontSize="2xl" fontWeight={"bold"}>
            Wins
          </Text>
          <Text fontSize="xl">{wins}</Text>
        </Box>
        <Box textAlign={"center"}>
          <Text fontSize="2xl" fontWeight={"bold"}>
            Loss
          </Text>
          <Text fontSize="xl">{losses}</Text>
        </Box>
      </Flex>
      <Box textAlign={"center"}>
        <Text fontSize={["xl", "2xl"]}>
          {gameActive ? `${turn} Turn` : "Game Over"}
        </Text>
      </Box>
    </Flex>
  );
};

export default GameStats;
