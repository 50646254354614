const allPossibleNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];

// returns all indexes that are blank
export const allowedPositions = (state) =>
  state.map((_, i) => i).filter((val) => val !== null);

// returns all possible (unused) values that can be placed on the board
export const allowedValues = (state) => {
  const usedValues = state.filter((val) => val !== null);
  const agentValues = allPossibleNumbers.filter(
    (val) => val % 2 !== 0 && !usedValues.includes(val)
  );
  const userValues = allPossibleNumbers.filter(
    (val) => val % 2 === 0 && !usedValues.includes(val)
  );

  return [agentValues, userValues];
};

// returns all possible actions, i.e, all combinations of allowed positions and allowed values
const actionSpace = (state) => {
  const [agentAllowedValues, userAllowedValues] = allowedValues(state);
  const agentActions = allowedPositions(state).flatMap((position) =>
    agentAllowedValues.map((value) => [position, value])
  );
  const userActions = allowedPositions(state).flatMap((position) =>
    userAllowedValues.map((value) => [position, value])
  );
  return [agentActions, userActions];
};

/*
  Takes state as an input and returns whether any row, column or diagonal has winning sum
  Example: Input state- [1, 2, 3, 4, null, null, null, null, null]
  Output = False
  */
const isWinning = (state) => {
  const winningStates = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];

  // check if the sum of any winning state is 15 and no value is null
  for (const indexes of winningStates) {
    const hasNoNull = indexes.every((index) => state[index] !== null);
    const sum = indexes.reduce((total, index) => total + state[index], 0);
    if (hasNoNull && sum === 15) return true;
  }

  return false;
};

// Terminal state could be winning state or when the board is filled up
const isTerminal = (state) => {
  if (isWinning(state)) return [true, "Win"];
  else if (allowedPositions(state).length === 0) return [true, "Tie"];
  else return [false, "Resume"];
};

function gameReducer(game, action) {
  switch (action.type) {
    case "USER": {
      game.state[action.payload[0]] = action.payload[1];
      const [gameOver, result] = isTerminal(game.state);
      if (gameOver) {
        if (result === "Win")
          return {
            ...game,
            playerWon: game.playerWon + 1,
            gameActive: false,
            totalGames: game.totalGames + 1,
          };
        else
          return {
            ...game,
            gameActive: false,
            totalGames: game.totalGames + 1,
          };
      } else
        return {
          ...game,
          isPlayerActive: false,
        };
    }
    case "AGENT": {
      if (!action.payload) {
        const possibleActions = actionSpace(game.state)[0];
        const randomAction =
          possibleActions[Math.floor(Math.random() * possibleActions.length)];
        game.state[randomAction[0]] = randomAction[1];
      } else {
        game.state[action.payload[0]] = action.payload[1];
      }
      const [gameOver, result] = isTerminal(game.state);
      if (gameOver) {
        if (result === "Win")
          return {
            ...game,
            agentWon: game.agentWon + 1,
            gameActive: false,
            totalGames: game.totalGames + 1,
          };
        else
          return {
            ...game,
            gameActive: false,
            totalGames: game.totalGames + 1,
          };
      } else
        return {
          ...game,
          isPlayerActive: true,
        };
    }
    case "RESET":
      return {
        ...game,
        isPlayerActive: false,
        currentUserChoice: null,
        state: Array(9).fill(null),
        gameActive: true,
      };
    default:
      throw new Error("Unknown Action Type");
  }
}

export default gameReducer;
