import { Box, Button } from "@chakra-ui/react";
import React from "react";

const TicTacToeCell = ({ value, onCellClick, disabled }) => {
  return (
    <Box height={"100%"} width={"100%"}>
      <Button
        onClick={onCellClick}
        height={"100%"}
        width={"100%"}
        fontSize={"24px"}
        disabled={disabled}
      >
        {value}
      </Button>
    </Box>
  );
};

export default TicTacToeCell;
