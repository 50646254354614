import Dexie from "dexie";

const db = new Dexie("model");

db.version(1).stores({
  actions: "",
});

export async function downloadModel() {
  const response = await fetch("/model.json");
  const modelJSON = await response.text();
  const data = JSON.parse(modelJSON);
  // Map over object to convert to dexie
  await db.actions
    .bulkAdd(
      Object.values(data).map((action) => action),
      Object.keys(data).map((state) => state)
    )
    .catch(Dexie.BulkError, function (e) {
      // Explicitely catching the bulkAdd() operation makes those successful
      // additions commit despite that there were errors.
      console.error(e.failures.length + " actions were not added");
    });
}

export default db;
